import { ReactComponent as DoRLogo } from './img/logo.svg'
import './App.scss'
import Helmet from 'react-helmet'
import SpotifyPlayer from 'react-spotify-player'

import {
  faBandcamp,
  faSpotify,
  faSoundcloud,
  faFacebook,
  faTwitter,
  faYoutube,
  faLastfm
} from '@fortawesome/free-brands-svg-icons'

import Link from './Link'

// size may also be a plain string using the presets 'large' or 'compact'
const spotify = {
  size: {
    width: '100%',
    height: '100%',
  },
  view: 'list', // or 'coverart'
  theme: 'black', // or 'white'
}

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Day Of Rising &mdash; a rock band</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <header className="Header">
        <h1>
          <DoRLogo />D<span style={{ letterSpacing: '-.1em' }}>ay</span> Of
          Rising
        </h1>
      </header>
      <main className="Body">
        <section className="Player">
          <SpotifyPlayer
            uri="spotify:album:3J7R6v1SEJVkiqF6nFSvT9"
            size={spotify.size}
            view={spotify.view}
            theme={spotify.theme}
          />
        </section>
      </main>
      <footer className="Footer">
        <Link
          title="Bandcamp"
          icon={faBandcamp}
          url="https://dayofrising.bandcamp.com/"
        />
        <Link
          title="SoundCloud"
          icon={faSoundcloud}
          url="https://soundcloud.com/dayofrising"
        />
        <Link
          title="Facebook"
          icon={faFacebook}
          url="https://facebook.com/dayofrising"
        />
        <Link
          title="Twitter"
          icon={faTwitter}
          url="https://twitter.com/dayofrising"
        />
        <Link
          title="last.fm"
          icon={faLastfm}
          url="https://www.last.fm/music/Day+Of+Rising"
        />
        <Link
          title="Spotify"
          icon={faSpotify}
          url="https://open.spotify.com/artist/1cCXdQD1COSbjvC85Md23p"
        />
        <Link
          title="YouTube"
          icon={faYoutube}
          url="https://www.youtube.com/user/dayofrisingofficial"
        />
      </footer>
    </div>
  )
}

export default App
